import { InvisibleSmartCaptcha } from '@yandex/smart-captcha';
import { useCallback, useEffect, useState } from 'react';

import { useStores } from '@shared/hooks';
import { observer } from 'mobx-react-lite';

export const Captcha = observer(() => {
  const {
    MainStore: {
      applicationStore: {
        isCaptchaVisible,
        setCaptchaToken,
        setIsCaptchaVisible,
      },
    },
  } = useStores();
  const [resetCaptcha, setResetCaptcha] = useState(0);

  const handleSuccess = useCallback(
    (token: string) => {
      setCaptchaToken(token);
    },
    [setCaptchaToken]
  );

  const handleReset = useCallback(() => {
    setIsCaptchaVisible(false);
  }, [setIsCaptchaVisible]);

  const handleTokenExpired = useCallback(() => {
    setCaptchaToken(undefined);
  }, [setCaptchaToken]);

  useEffect(() => {
    if (!isCaptchaVisible) {
      setResetCaptcha((prev) => prev + 1);
    }
  }, [isCaptchaVisible]);

  return (
    <InvisibleSmartCaptcha
      key={resetCaptcha}
      sitekey={window.envKeys.CAPTCHA_CLIENT_KEY}
      visible={isCaptchaVisible}
      hideShield={true}
      onSuccess={handleSuccess}
      onChallengeHidden={handleReset}
      onJavascriptError={handleReset}
      onNetworkError={handleReset}
      onTokenExpired={handleTokenExpired}
    />
  );
});
