import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorCode, hasError, phoneValueCasting } from '@shared/utils';
import { useRequest, useStores } from '@shared/hooks';
import { Endpoints, QUERY_KEYS } from '@shared/constants';

import { AuthenticationResponseType } from '../auth-box.type';

export const useHandleAuthentication = (
  setFormValueError: (fieldName: string, error: any) => void
) => {
  const { t } = useTranslation();
  const {
    MainStore: {
      errorStore: { setErrorRetry, setIsLetsTakeABreakErrorVisible },
      authStore: {
        callAuth,
        refetchAuthentication,
        authTokens,
        setRefetchAuthentication,
        setAuthIsLoading,
        setDisableForm,
        setAuthError,
        handleStoreAuthResponse,
        setCaptchaIsNotValidError,
        handleAuth,
      },
      applicationStore: { setIsCaptchaVisible, captchaToken, setCaptchaToken },
    },
  } = useStores();

  const requestData = {
    authenticationUser: { phoneNumber: phoneValueCasting(callAuth) },
  };

  const { isLoading, error, res, refetch } =
    useRequest<AuthenticationResponseType>(
      QUERY_KEYS.auth,
      'post',
      Endpoints.AUTHENTICATION,
      requestData,
      [callAuth, captchaToken],
      true,
      undefined,
      {
        'X-URK': captchaToken,
        'X-TIMESTAMP': new Date().getTime(),
      }
    );

  useEffect(() => {
    const shouldRefetch =
      callAuth &&
      !authTokens &&
      (!res || refetchAuthentication) &&
      !!captchaToken;

    if (shouldRefetch) {
      refetch();

      if (refetchAuthentication) setRefetchAuthentication(false);
    }
  }, [callAuth, res, authTokens, refetchAuthentication]);

  useEffect(() => {
    setAuthIsLoading(isLoading);
    if (!isLoading) {
      if (res) {
        setIsCaptchaVisible(false);
        setCaptchaToken(undefined);
        setErrorRetry(false);
        handleStoreAuthResponse(res);
      }
      if (hasError(error, ErrorCode.RATE_LIMIT_EXCEEDED)) {
        setIsCaptchaVisible(false);
        setCaptchaToken(undefined);

        if (!!error?.response?.data.unlockingTime) {
          setDisableForm(false);
          setAuthError(true);
          setFormValueError(
            'WhoIssuesPolicy.phone',
            t('COMMON:errors.authLimitExceeded')
          );
        } else {
          setIsLetsTakeABreakErrorVisible(true);
          setIsCaptchaVisible(false);
          setCaptchaIsNotValidError(true);
          handleAuth('');
        }
      }
    }
  }, [isLoading, error, res]);

  return {
    isLoadingAuthentication: isLoading,
    errorAuthentication: error,
    resAuthentication: res,
    refetchAuthentication: refetch,
  };
};
