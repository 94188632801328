/* eslint-disable indent */
import { Suspense, useMemo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import { CurrencyLabel } from '@shared/constants';
import { useStores } from '@shared/hooks';
import { numFormat, getPurchaseRoute } from '@shared/utils';

import type { FC } from 'react';
import { Submit } from '@entities/import-smart-components/submit';
import {
  FormBuilderEventBus,
  FormBuilderAction,
} from '@features/form-builder/utils';
import {
  getFormInsuranceContactsSchema,
  getPhoneValidationSchema,
} from '@shared/scheme';

export const SubmitAdapter: FC = observer(() => {
  const { t } = useTranslation();
  const {
    MainStore: {
      applicationStore: {
        currentStep,
        disabledSubmit,
        updateFormValue,
        isExternalPayment,
        flowConfig: { otpStep, maxSteps },
        setIsCaptchaVisible,
        captchaToken,
        setCaptchaToken,
      },
      authStore: {
        setExternalAuthPhone,
        hasAuthorized,
        hasPhone,
        handleOtp,
        externalAuthPhone,
        setDisableForm,
        otpIsLoading,
        authIsLoading,
        disableForm,
        otpErrorCode,
        setOtpErrorCode,
        authError,
        useOtp,
        setRefetchAuthentication,
        captchaIsNotValidError,
        setCaptchaIsNotValidError,
      },
      productStore: {
        setPulseClient,
        pulseClient,
        formState,
        formString,
        price,
        hasPricesCalcErr,
        orderData,
        setPrice,
        agentLogin,
        clearPromoCode,
      },
    },
  } = useStores();

  const [route, setRoute] = useState<string>();

  const buttonText = useMemo(() => {
    const paymentVal = numFormat(Number(orderData?.premiumAndDelta || ''));
    const currency = CurrencyLabel.RUB;

    switch (currentStep) {
      case maxSteps:
        return t('COMMON:labels.getPayment', {
          paymentVal,
          currency,
        });
      case otpStep:
        return hasAuthorized ? undefined : t('COMMON:labels.submitGetCode');
      default:
        return undefined;
    }
  }, [currentStep, hasAuthorized, orderData?.premiumAndDelta]);

  // генерация урла для открытия в новой вкладке Б2П
  useEffect(() => {
    if (currentStep === maxSteps && formState?.B2P?.initOrder) {
      const value = formState?.B2P;
      setRoute(value ? getPurchaseRoute(value) : undefined);
    }
    if (currentStep !== maxSteps) {
      setRoute(undefined);
    }
  }, [currentStep, maxSteps, formState?.B2P]);

  const handleSubmit = () => {
    if (currentStep === maxSteps) {
      updateFormValue('B2P', {
        ...formState.B2P,
        ...{ showInitOrder: true, isExternalPayment: isExternalPayment },
      });
    }

    if (
      currentStep === maxSteps &&
      formState?.DocumentsList?.acceptRequirements
    ) {
      updateFormValue('DocumentsList', {
        ...formState?.DocumentsList,
        acceptRequirements: false,
        isValid: false,
      });
      updateFormValue('B2P', {
        ...formState.B2P,
        ...{ showInitOrder: true, isExternalPayment: isExternalPayment },
      });
    }

    FormBuilderEventBus.publish<null>(FormBuilderAction.SUBMIT_FORM, null);
  };

  const authUserHandler = useCallback((phone: string) => {
    FormBuilderEventBus.publish(FormBuilderAction.AUTH_USER, {
      phone,
    });
  }, []);

  useEffect(() => {
    if (formState?.WhoIssuesPolicy) {
      setPulseClient(formState?.WhoIssuesPolicy?.client || false);
    }

    if (
      !formState?.WhoIssuesPolicy?.client &&
      getFormInsuranceContactsSchema(agentLogin).isValidSync(
        formState?.WhoIssuesPolicy
      )
    ) {
      setExternalAuthPhone(formState?.WhoIssuesPolicy?.phone);
    } else if (
      formState?.WhoIssuesPolicy?.client &&
      getPhoneValidationSchema(agentLogin).isValidSync(
        formState?.WhoIssuesPolicy?.phone
      )
    ) {
      setExternalAuthPhone(String(formState?.WhoIssuesPolicy?.phone));
    } else {
      setExternalAuthPhone(undefined);
    }
  }, [formString]);

  const shouldHideSubmit = useMemo(() => {
    const isAgentFlow = !!agentLogin;
    const isPaymentStep = currentStep === maxSteps;

    return isPaymentStep && isAgentFlow;
  }, [currentStep, maxSteps, agentLogin]);

  return (
    <Suspense>
      <Submit
        buttonText={buttonText}
        onSubmit={handleSubmit}
        hasAuthorized={hasAuthorized}
        useOtp={useOtp && currentStep === otpStep}
        hasPhone={hasPhone}
        handleOtp={handleOtp}
        handleAuth={authUserHandler}
        externalPhone={externalAuthPhone}
        setDisableForm={setDisableForm}
        otpIsLoading={otpIsLoading}
        authIsLoading={authIsLoading}
        disabled={
          disableForm ||
          disabledSubmit ||
          (currentStep === maxSteps &&
            (formState?.DocumentsList?.acceptRequirements === false ||
              !formState?.DocumentsList?.acceptRequirements))
        }
        hideSubmit={shouldHideSubmit}
        hasPricesCalcErr={hasPricesCalcErr}
        otpErrorCode={otpErrorCode}
        setOtpErrorCode={setOtpErrorCode}
        authError={authError}
        captchaIsNotValidError={captchaIsNotValidError}
        setCaptchaIsNotValidError={setCaptchaIsNotValidError}
        currentStep={currentStep}
        refetchAuthentication={setRefetchAuthentication}
        pulseClient={pulseClient}
        onSetPrice={setPrice}
        onClearPromoCode={clearPromoCode}
        price={price}
        isShowPromoCode={currentStep === otpStep && hasAuthorized}
        b2pRoute={route}
        setIsCaptchaVisible={setIsCaptchaVisible}
        captchaToken={captchaToken}
        setCaptchaToken={setCaptchaToken}
      />
    </Suspense>
  );
});

SubmitAdapter.displayName = 'SubmitAdapter';
